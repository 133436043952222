.errorPage {
  .swiper-container {
    max-width: 760px;
    margin: 0 auto;
  }
  .searchBlock--hp {
  }
  h2 {
    margin-bottom: (1.618em);
    padding: 0;
  }
  @media (min-width: 768px) {
    .intro-section {
      .swiper-slide {
        max-width: 43%;
      }
    }
  }
  @media (min-width: 1200px) {
    .searchBlock--hp {
      padding: 180px 0;
      header p {
        font-size: 24px;
      }
    }
  }
}
