.modal {
  &-dialog {
  }
  &-content {
    padding: 20px;
    @media (min-width: 768px) {
      padding: 55px 70px;
    }
  }
  &-header,
  &-body {
    padding: 0;
  }
  &-title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 30px;
    padding: 0;
  }
  .perex {
    line-height: 22px;
    max-width: 400px;
    font-weight: 500;
  }
  .subPerex {
    margin-bottom: 30px;
    line-height: 22px;
    font-weight: 400;
  }
  &-body {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }
}
#kontaktModal {
  .modal {
    &-dialog {
      max-width: 380px;
    }
    &-body {
      font-size: 15px;
      font-weight: 300;
      line-height: 30px;
    }
    &-title {
      margin-bottom: 60px;
    }
  }
  &.in {
    .modal-dialog {
      padding: 15px;
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);;
    }
  }
}
