.padding-xs {
	padding: $padding-xs-vertical $padding-xs-horizontal;
}

.padding-sm {
	padding: $padding-small-vertical $padding-small-horizontal;
}

.padding-md {
	padding: $padding-base-vertical $padding-base-horizontal;
}

.padding-lg {
	padding: $padding-large-vertical $padding-large-horizontal;
}

.padding-xl {
	padding: ($padding-large-vertical * 2) ($padding-large-horizontal * 2);
}

.padding-vertical-xs {
	padding: $padding-xs-vertical 0;
}

.padding-vertical-sm {
	padding: $padding-small-vertical 0;
}

.padding-vertical-md {
	padding: $padding-base-vertical 0;
}

.padding-vertical-lg {
	padding: $padding-large-vertical 0;
}

.padding-vertical-xl {
	padding: ($padding-large-vertical * 2) 0;
}

.padding-horizontal-xs {
	padding: 0 $padding-xs-horizontal;
}

.padding-horizontal-sm {
	padding: 0 $padding-small-horizontal;
}

.padding-horizontal-md {
	padding: 0 $padding-base-horizontal;
}

.padding-horizontal-lg {
	padding: 0 $padding-large-horizontal;
}

.padding-horizontal-xl {
	padding: 0 ($padding-large-horizontal * 2);
}

.margin-xs {
	margin: $padding-xs-vertical $padding-xs-horizontal;
}

.margin-sm {
	margin: $padding-small-vertical $padding-small-horizontal;
}

.margin-md {
	margin: $padding-base-vertical $padding-base-horizontal;
}

.margin-lg {
	margin: $padding-large-vertical $padding-large-horizontal;
}

.margin-xl {
	margin: ($padding-large-vertical * 2) ($padding-large-horizontal * 2);
}

.margin-vertical-xs {
	margin: $padding-xs-vertical 0;
}

.margin-vertical-sm {
	margin: $padding-small-vertical 0;
}

.margin-vertical-md {
	margin: $padding-base-vertical 0;
}

.margin-vertical-lg {
	margin: $padding-large-vertical 0;
}

.margin-vertical-xl {
	margin: ($padding-large-vertical * 2) 0;
}

.margin-horizontal-xs {
	margin: 0 $padding-xs-horizontal;
}

.margin-horizontal-sm {
	margin: 0 $padding-small-horizontal;
}

.margin-horizontal-md {
	margin: 0 $padding-base-horizontal;
}

.margin-horizontal-lg {
	margin: 0 $padding-large-horizontal;
}

.margin-horizontal-xl {
	margin: 0 ($padding-large-horizontal * 2);
}
