.card-block__center {
  border-left: 1px solid #bdbdbd;
}

.card-block__center:nth-child(2) {
  border-right: 1px solid #bdbdbd;
}

.resultTableSpace {
  min-height: 30rem;
}

.resultHead {
  height: 2.5rem;
  background-color: white;
}

.resultHead__item {
  vertical-align: middle !important;
  border-bottom: 0.2rem solid rgb(61, 205, 88) !important;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 400;
}

.resultRow:nth-child(2n) {
  background-color: #f8f8f8;
}

.prodCode {
  padding-bottom: 0;
}

.sellingDelivery {
  text-align: center;
  display: inline-block;
  font-size: 1.5rem;
  padding-bottom: 2rem;
}

.prodDesc {
  font-style: italic;
}

.resultItem {
  border: none;
  vertical-align: middle;
  text-align: center;
}

.result-divider {
  margin: 0 15px;
  display: inline-block;
}

.mobileLabel {
  display: inline-block;
  text-align: center;
}

.prod {
  &Code {
    font-size: 20px;
    margin-right: 15px;
    margin-bottom: 12px;
    display: inline-block;
    line-height: 1;
  }
  &Desc {
    font-weight: 300;
    font-style: italic;
  }
}

.sellingPlace {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
  span {
    font-size: 14px;
  }
}

a.prodDesc {
  margin-bottom: 12px;
  display: inline-block;
  line-height: 1;
}

.eplusCheck {
  display: inline-block;
  width: 2rem;
  height: 1.3rem;
  border-left: 3px solid rgba(61, 205, 88, 1);
  border-bottom: 3px solid rgba(61, 205, 88, 1);
  transform: rotate(-45deg);
  top: -.6rem;
  margin: auto;
}

.modal.in .modal-dialog {
  margin: 0 auto;
}
.detailTable {
  margin-bottom: 50px;
} 

@media (min-width: 920px) {
  .resultTable:not(.detailTable) {
    td:first-child,
    th:first-child {
      padding-left: 50px;
    }
    td:first-child {
      padding-right: 30px;
    }
  }
  .resultItem {
    table.resultTable & {
      padding-top: 3.5rem;
      padding-bottom: 2.5rem;
    }
    table.resultTable--massive & {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .buttonsColumn {
    padding-right: 5rem; 
  }
  .tableInfo {
    padding-left: 5rem;
    text-align: left;
  }
  .card-block__centerBody {
    display: flex;
    min-height: 7rem;
    // margin: -8px;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #bdbdbd;
  }
  .fixcolumn {
    width: 17rem;
  }
  .resultItem:nth-child(3) .card-block__centerBody {
    border-right: 1px solid #bdbdbd;
    flex-direction: column;
  }
  .card-block__centerBody--smaller {
    min-height: 4.5rem;
    padding: 0 2rem;
  }
  .mobileLabel {
    display: none;
  }
  .buttonsColumn {
    min-width: 38rem;
  }
}

@media (max-width: 920px) {
  #searchResult, #massiveResult {
    .resultRow {
      &:not(:last-child) {
        box-shadow: inset 0 -2px 0 0 #eaeaea;
      }
    }
    .tableInfo {
      text-align: left;
      padding-top: 32px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(black, 0.08);
      .prodCode {
        font-size: 24px;
      }
      .prodCode,
      .prodDesc {
        display: block;
        margin: 0 0 5px;
      }
      .prodDesc {
        margin: 0;
        font-size: 12px;
      }
    }
    .sellingDelivery {
      font-size: 12px;
    }
    .mobileRow {
      &:nth-child(odd) {
        background-color: rgba(black, 0.05);
      }
      &:nth-child(even) {
        background-color: rgba(white, 0.5);
      }
      &:last-child {
        background-color: transparent;
        padding: 0 0 32px;
        .sellingPlace {
          background-color: rgba(white, 0.5);
          border-bottom: 1px solid rgba(black, 0.08);
          padding: 8px;
          span {
            font-size: 12px;
          }
        }
        .btnArea {
          .btn-inList {
            min-width: 0;
          }
        }
      }
      .card-block__centerBody,
      .mobileFlex,
      .sellingPlace {
        display: flex;
        justify-content: space-between;
      }
      .mobileLabel,
      .columnValue {
        font-size: 13px;
      }
      .columnValue {
        display: inline-block;
      }
      .result {
        &-time,
        &-date {
          display: inline-block;
          margin-right: 0.3em;
        }
        &-eplus {
          display: block;
          text-align: center;
        }
      }
    }
  }
  .eplusCheck {
    margin: 0;
  }
  .desktopLabel {
    display: none;
  }
  .card-block thead {
    display: none;
  }
  .resultItem:not(.resultItem--detail) {
    display: block;
    width: 90%;
    margin: auto;
    text-align: left;
  }
  .resultHead {
    display: none;
  }
  .buttonsColumn {
    padding-bottom: 2rem;
  }
  .card-block__center {
    vertical-align: top;
    border: none !important;
  }
  .btnGroup-inList {
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  .btn-inList {
    width: 49%;
  }
  .resultMessage {
    text-align: center;
  }
  .resultLine {
    display: none;
  }
}

.resultItem--detail {
  padding: 1rem 0 !important;
  height: auto;
}
