@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@import "components/variables";
@import "components/swiper";
@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "components/common";
@import "components/content-box";
@import "injected";
@import "components/topheader";
@import "components/kontaktModal";
@import "components/slider";
@import "components/modal";
@import "components/form";
@import "components/menu";
@import "components/search";
@import "components/effects";
@import "components/footer";
@import "components/results-table";
@import "components/seller-table";
@import "components/intro-section";
@import "components/list-dotted";
@import "components/errorPage";

.medium {
  font-weight: 500;
} 

.light {
  font-weight: 300;
}

.nowrap {
  white-space: nowrap;
}

.background-gray {
  background-color: #f7f7f7;
}
.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pb-0 {
  padding-bottom: 0;
}
.mt-40 {
  margin-top: 40px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}