.footer{
  background-color: #636469;
}

.bottomFooter__text{
  text-align:center;
  color:white;
}
.bottomFooter{
  padding:6rem 0;
}