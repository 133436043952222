.sliderSection {
}

.sliderSection__slide {
  display: flex;
  align-items: flex-start;
  min-height: 28rem;
  min-height: 15vw;
  padding: 30px 0;
  background-size: cover;
  background-position: center;
}

.sliderInner {
  padding: 2rem;
  display: flex;
  align-items: center;
}

.sliderTextArea {
  width: 50%;
  text-align: right;
  padding-right: 5rem;
  font-size: 18px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
  .headerText {
    font-size: 24px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 30px;
    }
  }
}

.slide1 {
  background-image: url("/img/slide1-bg.jpg");
}

.slide2 {
  background-image: url("/img/banner_poukazky.jpg");
}

@media (max-width: 920px) {
  .sliderInner {
    flex-direction: column;
  }
  .sliderTextArea {
    width: 100%;
    text-align: center;
    padding-right: 0rem;
  }
}
