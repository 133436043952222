.modal-content {
  border-radius: 0;
}

.modal-header {
  border: 0;
  .close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
    width: 4rem;
    height: 4rem;
    line-height: 0;
    font-size: 4rem;
  }
}

.modal {
  &.in {
    @media (min-width: 768px) {
      .modal-dialog {
        padding: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

#introModal {
  .modal-content {
    @media (min-width: 768px) {
      background: {
        image: url(../images/esklady-color.svg);
        position: 90% 25px;
        repeat: no-repeat;
        size: 100px auto;
      }
    }

    @media (min-width: 1200px) {
      background: {
        size: 200px auto;
        position: 90% 40px;
      }
    }
  }
}
