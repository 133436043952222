.searchBlock{
  background-color: #eaeaea;
  padding: 3rem;
  width:100%;
}
.searchBlock__switcher {
  display: flex;
  width:100%;
  max-width:78.5rem;
  margin:auto;
  font-size: 1.5rem;
  text-transform:uppercase;
  padding-bottom: 2rem;
}
a.switcher__option {
  width:50%;
  text-decoration: none;
  text-align:center;
  text-decoration:none;
  color: rgba(157,157,157,1);
  /*#d2d2d2*/
  flex-direction: column;
  display: flex;
}
.switcher__option:hover,
.switcher__option:focus {
  text-decoration:none;
}
.searchBlock__form{
  display:flex;
  align-items:flex-start;
  justify-content: center;
  width: 100%;
}
.searchBlock__mainColumn {
  label {
    margin: 0;
  }
}
.searchBlock__mainColumn {
  & + input.searchForm__item {
    padding-top: 0;
    padding-bottom: 0;
    height: 52px;
    line-height: 52px;
  }
  .searchForm__item{
    flex: 1 1 auto;
    margin: 0 0 20px 0;
    @media (min-width: 768px) {
      margin: 0 5px 20px 0;
    }
    input {
      padding-top: 0;
      padding-bottom: 0;
      height: 52px;
      line-height: 52px;
    }
    &.btn {
      flex-shrink: 0;
    }
  }
}
.searchForm__item.text{
  margin-right: .5rem;
}
.searchForm__item--eplus{
  width:100%;
}
.searchBlock__mainColumn{
  display:flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
}
.switcherLabel{
  padding:1rem;
}
.buttonSearchSize{
  max-width:18rem;
}
.resultMessage{
  text-transform: uppercase;
  text-align:center;
  position:relative;
  padding: 20px 0 30px;
  line-height: 1;
  @media (min-width: 768px){
    line-height: 1.58;
    padding: 4rem 0 5rem;
  }
}
.resultMessage__bigger{
  font-size:18px; 
  display:inline-block;
  background-color: white;
  padding: 0 15px;
  @media (min-width: 768px) {
  padding:0 3rem;
    font-size:3rem;
  }
}
.resultMessage__smaller{
  font-size: 11px;
  padding: 0 15px;
  display:inline-block;
  @media (min-width: 768px) {
    padding:0 3rem;
    font-size:1.8rem;
    display: block;
    max-width: 500px;
    margin: 0 auto;
  }
  background-color: white;
}
.resultLine{
  position: absolute;
  top: 38%;
  width: 100%;
  z-index: -1;
}
.breadcrumb{
  background: white;
}
.searchBlock--hp{
  overflow:hidden;
  width:100%;
  background-color:transparent;
  display:flex;
  background-image: url('/img/vyhledavani-bg.jpg');
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  @media (min-width: 768px) {
    padding: 100px 20px 150px;
  }
  @media (min-width: 1200px) {
    padding: 180px 20px 320px;
  }
}
.withExample{
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  margin-bottom: 20px;
  width: 100%;
  //border-right: .5rem solid #eaeaea;
  @media(min-width: 768px) {
    width: auto;
    margin-bottom: 0;
    margin-right: .5rem;
  }
}
#prodExamples {
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 100%;
  left: 0;
}
.prodExample__item,
.cityExample__item{
  display: block;
  padding: .5rem 3rem;
  font-size: 1.5rem;
  color: #9d9d9d;
  background-color: white;
  border-left: 1px solid #9d9d9d;
  border-right: 1px solid #9d9d9d;
  transition: background-color 190ms ease-in-out;
}
.prodExample__item:first-child,
.cityExample__item:first-child{
  border-top: 1px solid #9d9d9d;
}
.prodExample__item:last-child,
.cityExample__item:last-child{
  border-bottom: 1px solid #9d9d9d;
}
.prodExample__item:hover,
.prodExample__item:focus,
.cityExample__item:hover,
.cityExample__item:focus{
  color: #9d9d9d;
  text-decoration:none;
  background-color: #fbfbfb;
}
.hpFormItem{
  height: 55px;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 100%;
    height: 8rem;
    font-size: 3.5rem;
  }
}
.hpFormItem.buttonSearchSize{
  padding-left: 0;
  padding-right: 0;
  @media (min-width: 768px) {
    max-width: 26rem;
  }
}
@media (max-width: 830px) {
  .searchBlock__form {
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
  .searchBlock__mainColumn{
    flex-direction: column;
  }
}

.headerTextSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align:center;
  justify-content: center;
  padding-bottom: 70px;

  &_3,
  &_1 {
    color: #efefef;
    line-height: 1.38;
  }

  &_1 {
    font-size: 14px;
  }

  &_2 {
    font-size: 36px;
    line-height: 1.1;
    text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
    font-weight: 700;
  }

  &_3 {
    font-size: 12px;
  }

  a {
    color: inherit;
  }

  @media (min-width: 768px) {
    &_1 {
      font-size: 30px;
    }

    &_2 {
      font-size: 50px;
      line-height: 1.5;
    }

    &_3 {
      font-size: 18px;
    }
  }
}