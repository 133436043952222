.e__modal {
	.modal-close {
		position: absolute;
		right: 160px;
		margin-top: -25px;	
	}
	.modal-dialog {
		margin-top: 0;
	}
	.modal-content {
	}
}
