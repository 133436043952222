.underEfect__item:after,
.underEfect__item--dark:after {
  content:'';
  background-color: rgba(61,205,88,0);
  width:0%;
  height:.5rem;
  margin:auto;
  position: absolute;
  bottom: -.5rem;
  left: 0;
  transition:
    width .3s,
    background-color .3s;
}
.underEfect__item--dark{
  position: relative;
  transition:
    color .3s;
}
.underEfect__item {
  transition: box-shadow 190ms ease-in-out;
}
.underEfect--active,
.underEfect__item:hover,
.underEfect__item:focus{
  span {
    display: block;
    border-bottom: 1px solid rgba(61,205,88,1);
  }
  @media (min-width: 768px) {
    span { border: 0}
    box-shadow: inset 0 -4px 0 0 rgba(61,205,88,1);
  }
}
.underEfect__item--dark:hover,
.underEfect__item--dark:focus,
.underEfect__item--dark--active{
  color: rgba(0,0,0,1);
}
.underEfect__item--dark{
  border-bottom: 5px solid rgba(99,99,107,.2);
}
.underEfect__item--dark--active:after,
.underEfect__item--dark:hover:after,
.underEfect__item--dark:focus:after{
  background-color: rgba(99,99,107,1);
  width:100%;
}
//
.aboveEfect__item:before{
  content:'';
  background-color: rgba(61,205,88,0);
  width:0%;
  height:.5rem;
  margin:auto;
  transition:
    width .3s,
    background-color .3s;
}
.aboveEfect--active:before,
.aboveEfect__item:hover:before,
.aboveEfect__item:focus:before{
  background-color: rgba(61,205,88,1);
  width:100%;
}
@media (max-width: 830px) {
  .aboveEfect__item:before,
  .underEfect__item:after{
    display:block;
  }
}
