$background-input : #e0e0e0;
$background-span : #f0f0f0;
$link-span : #929292;
$background-span : #f0f0f0;
.e__input-group {
	// ve vychozim stavu neni videt validacni zprava

	.validation-message {
		display: none;
	}
	// pokud je error nebo warning, je videt validacni zprava
	&.has-error, &.has-warning {
		.help-block:not(.validation-message) {
			display: none;
		}
		.help-block.validation-message {
			display: block;
		}
	}

	// input group button
	.input-group-addon-button {
		cursor: pointer;
		background: $background-span;

		&:hover {
			background-color: $gray-lighter;
		}
		&:focus {
			background-color: $gray-lighter;
		}

		a{
			color: $link-span;
			text-decoration: none;
			&:hover {
				text-decoration: none;
			}
		}
	}

}
