.menu {
  display: flex;
  justify-content:space-between;
}
.menu__item {
  text-decoration:none;
  overflow: hidden;
  display: inline-block;
}
.menu__item:hover,
.menu__item:focus {
  text-decoration:none;
}
.menuLabel{
  text-align:center;
  text-transform: uppercase;
  color: black;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 15px 10px;
  @media (min-width: 768px) {
    display:inline-block;
    padding: 3rem 1rem 2.5rem;
    font-size: 2rem;
  }
}
.menuLabel--light{
  color: white;
}
.menuLabel--blue{
  color: #43b3e5;
  justify-content: space-around;
}
.menuLabel--small{
  font-size: 1.5rem;
  padding: 2rem 1rem 2.5rem;
}
.hamburger{
  display:none;
  line-height: 0;
  white-space: nowrap;
  padding: 15px 0;
  &-box {
    width: 30px;
  }
  &-inner {
    &, &:before, &:after {
      width: 30px;
      background-color: #fff;
    }
  }
}
.hamburger-label{
  font-size: 1.8rem;
  color: #fff;
}
@media (max-width: 830px) {
  .hamburger{
    display:block;
  }
  .menuLabel{
    padding:1rem 2.5rem;
  }
  .menu{
    flex-direction: column;
  }
  .menuLabel{
    display:block;
    text-align:left;
  }
  .menu__item{
    width:100%;
    display:block;
  }
  .menuItem--noMob{
    display:none;
  }
  .menuLabel--blue{
    text-align: center;
  }
}