.text{
  padding:1.5rem 2rem;
  @media (min-width: 768px) {
    padding:1.5rem 3rem;
  }
  font-size:1.5rem;
  border: none;
  color:#9d9d9d;
  &::placeholder {
    color: #d0d0d0;
    font-weight: 300;
  }
}
.btn-empty{
  padding:1.4rem 4.9rem;
  border:1px solid #43b3e5;
  color: rgb(0,0,0);
  background-color: transparent;
  &:hover {

    background-color: rgba(#43b3e5, .2);
  }
}
.btn-blue{
  padding:1.5rem 5rem;
  border: none;
  color:white;
  background-color: #43b3e5;
  &:hover {
    background-color: #0092d6;
    color: white;
  }
}
.btn{
  text-transform: uppercase;
  font-size:1.5rem;
  transition: background-color 190ms ease-in-out;
  background-size:220% 100%;
  border-radius:0;
  cursor: pointer;
}

.btn-inList{
  margin-bottom: .5rem;
  min-width: 18rem;
  padding:1rem 3rem;
  font-size:1.5rem;
}
.btn-empty.btn-inList{
  padding:.9rem 2.9rem;
}
.btn-white{
  border-color:white;
  color:white;
}
.btn-white:hover,
.btn-white:focus{
  color:white;
}
textarea{
  width:100%;
}
label{
  font-weight:normal;
}
.btn-area{
  display:flex;
}

// selectbox external by https://codepen.io/Vestride/pen/dABHx?editors=1100 
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    padding-left: 3rem;
    font-size: 12px;
    @media (min-width: 768px) {
      font-size: 15px;
    }
  }

  // Box.
  & + label:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin-right: 1rem;
    display: inline-block;
    vertical-align: text-top;
    width: 2rem;
    height: 2rem;
    background: white;
  }

  // Box hover
  /*&:hover + label:before {
    background: rgba(61,205,88,1);
  }*/
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 .3rem rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: rgba(61,205,88,1);;
  }
  
  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: .5rem;
    top: 1rem;
    background: white;
    width: .2rem;
    height: .3rem;
    box-shadow: 
      .2rem 0 0 white,
      .4rem 0 0 white,
      .4rem -.2rem 0 white,
      .4rem -.4rem 0 white,
      .4rem -.6rem 0 white,
      .4rem -.8rem 0 white;
    transform: rotate(45deg);
  }
}

.redirect--select--wrapper{
  width: 100%;

  .redirect--select{
    height: 52px;
    border-radius: 0;
    border: none;
  }

  option{
    padding: 0;
    width: 100%;
  }
}