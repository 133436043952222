.w__field-select-stepper {
}

.form-layout-horizontal .w__field-select-stepper {
	width: 47%;
	display: inline-block;
	.e__form-group {
		display: block;
		width: auto;
	}
	&:last-child {
		width: 53%;
		.e__form-group {
			padding-left: 12%;
		}
	}
}
