input, select{
	&.e__form-control{
		background:white;
		border-radius: 3px !important;
		box-shadow: none;
		border: 1px solid #d4ae68;
		&:hover, &:active, &:link, &:visited, &:focus{
			border: 1px solid $brand-primary !important;
			box-shadow: none !important;
		}
	}
}
