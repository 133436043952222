.w__form-repeater {
	.form-repeater-add-item {}
	
	.repeatable {
		transition: max-height 200ms ease-out;
	}

	// odebírací tlačítko v repeateru
	.form-repeater-remove-item {
		position: absolute;
		z-index: 100;
		display:none;
		background-color: $brand-primary !important;
		padding: 0 !important;
		margin: 0 !important;
		border-radius: 50%;
		font-size: 10px;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		width: 16px;
		height: 16px;
		.e__icon {
			display: inline-block;
			width: 0px;
			top: 1px;
			color: #fff;
			margin: 0 0 0 -50% !important;
			padding: 0 !important;
		}
	}
}
