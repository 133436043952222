ul.dotted {
  padding-left: 0;
  list-style: none;
  li {
    position: relative;
    padding-left: 35px;
    &:before {
     content: '';
     position: absolute;
     width: 12px;
     height: 12px;
     border-radius: 50%;
     background-color: #3dcd58;
     left: 0;
     top: 7px;
    }
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}