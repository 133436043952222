.topHeader {
	padding: 0 20px;
	background-color: rgb(61, 205, 88);
}
.topHeaderInner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.topHeader__part {
	display: inline-block;
	.headline {
		margin: 31px 0;
		max-width: 154px;
		min-width: 150px;
		max-height: 66px;
	}
}
@media (max-width: 680px) {
	.topHeader__part:nth-child(2) {
		display: none;
	}
}
@media (max-width: 840px) {
	.topHeader {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		height: auto;
	}
}

@media (min-width: 831px) {
	.topHeaderInner {
		align-items: flex-start;
	}
	.topHeader__part {
		.mobileLogo {
			margin: 20px 0;
			height: 33px;
		}
	}
}
