.collapsible-toggle {
	&.collapsed {
		.collapsed {
			display:block;
		}
		.expanded {
			display:none;
		}
	}

	.collapsed {
		display:none;
	}
	.expanded {
		display:block;
	}
}
