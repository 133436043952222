html {
  font-size: 62.5%; /*1180*/
}
a.btn,
a.menu__item {
  text-decoration: none;
}
a{
  text-decoration: underline;
}
table.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td{
  border: 0;
  vertical-align: middle;
}
.inWrap{
  width:100%;
  max-width:1180px;
  margin: auto;
}
.inWrap--smaller{
  max-width:980px;
}
.hideIt{
  display:none;
}
.textSection{
  padding:100px 2rem 80px 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align:center;
  justify-content: center;
}
.homePhone{
  font-size:4.8rem;
  font-weight: 300;
}
.homeMail {
  font-size: 16px;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}
h2,
.h2{
  margin:0;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    font-size: 30px;
  }
}
h3,
.h3{
  margin:0;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 2rem;
}
h4,
.h4{
  font-weight: 600;
  padding-bottom: 2rem;
}

.commonText{
  display:block;
  font-size:1.5rem;
  color:black;
}
.uppercase{
  text-transform: uppercase;
}
.white{
  color:white;
}
.multiColumn{
  display: flex;
  justify-content: space-between;
  padding:0 15px;
  @media (min-width: 768px) {
    padding:0 3rem;
  }
}
.multiColumn__column{
  width:45%
}
.alignRight{
  text-align: right;
}
.strong{
  font-weight: 600;
}
.darkSection{
  background-color:#f8f8f8;
  padding: 2rem 15px;
  @media (min-width: 768px) {
    padding: 2rem 3rem;
  }
  margin-bottom:2rem;
}
.lightLink{
  color:white;
  text-decoration: underline;
}
.lightLink:hover,
.lightLink:target,{
  color:#9d9d9d;
}
.mobileLogo{
  width: 100%;
  max-width: 186px;
  padding-left: 3rem;
}
@media (max-width: 1180px){   
  .homePhone{
    font-size: 4.1vw;
  }
}
@media (max-width: 840px) {
  .textSection{
    padding:12% 2rem 9% 2rem;
  }
  .multiColumn__column{
    width:100%;
  }
  .multiColumn{
    flex-direction: column;
  }
  .homePhone {
    font-size: 2.9rem;
  }
}
@media (max-width: 440px) {
  .sellerItem {
    // display:block;
  }
}
@media (max-width: 360px) {
  .btn{
    width:100%;
    max-width:100%;
    padding: 1.4rem 0rem;
  }
  .homePhone {
    font-size: 8vw;
  }
}

button.close {
  span {
    font-weight: 300;
  }
}

.contactTable {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 15px;
  }
  line-height: 2;
}
#kontaktModal {
  .modal-body {
    & > div {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.btn:active, .btn.active {
  background-color: #0092d6;
}