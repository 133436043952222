$arrowW: 86px;
$arrowH: 28px;
.intro {
  &-section {
    padding: 80px 20px 50px;
    .swiper {
      &-wrapper {
        justify-content: space-between;
        margin-bottom: 70px;
        display: flex;
      }
      &-slide {
        padding-top: 20px;
        flex-shrink: 0;
        width: 100%;
      }
    }
  }
  &-item {
    text-align: center;
    position: relative;
    &:hover {
      .intro-image {
        transform: scale(1.1);
      }
    }
  }
  &-image {
    transition: transform 190ms ease-in-out;
    margin-bottom: 40px;
  }
  &-description,
  &-detail {
    font-size: 24px;
    line-height: 1.618;
    a {
      color: inherit;
    }
  }

  // neni slider
  @media (min-width: 500px) {
    &-description,
    &-detail {
      font-size: 12px;
      line-height: 1.618;
    }
    &-section {
      .swiper {
        &-slide {
          max-width: 30%;
          flex-shrink: 1;
          &:not(:last-child) {
            .intro {
              &-item {
                &:after {
                  content: "";
                  position: absolute;
                  display: block;
                  left: 100%;
                  transform: translateY(-50%);
                  background: {
                    image: url(../images/arrow.svg);
                    size: ($arrowW / 2) ($arrowH / 2);
                    position: center;
                    repeat: no-repeat;
                  }
                  top: 25px;
                  width: ($arrowW / 2);
                  height: ($arrowH / 2);
                }
              }
            }
          }
        }
      }
    }
    &-image {
      margin-bottom: 20px;
      img {
        height: 50px;
      }
    }
  }
  @media (min-width: 768px) {
    &-description,
    &-detail {
      font-size: 16px;
    }
    &-section {
      .swiper {
        &-slide {
          max-width: 30%;
          &:not(:last-child) {
            .intro {
              &-item {
                &:after {
                  top: 35px;
                }
              }
            }
          }
        }
      }
    }
    &-image {
      margin-bottom: 20px;
      img {
        height: 70px;
      }
    }
  }

  @media (min-width: 990px) {
    &-section {
      .swiper {
        &-slide {
          &:not(:last-child) {
            .intro {
              &-item {
                &:after {
                  top: 43px;
                  width: ($arrowW / 1.5);
                  height: ($arrowH / 1.5);
                  background-size: ($arrowW / 1.5) ($arrowH / 1.5);
                }
              }
            }
          }
        }
      }
    }
    &-description,
    &-detail {
      font-size: 20px;
    }
    &-image {
      margin-bottom: 30px;
      img {
        height: 86px;
      }
    }
  }

  @media (min-width: 1200px) {
    &-section {
      .swiper {
        &-slide {
          &:not(:last-child) {
            .intro {
              &-item {
                &:after {
                  top: 50px;
                  width: $arrowW;
                  height: $arrowH;
                  background-size: $arrowW $arrowH;
                }
              }
            }
          }
        }
      }
    }
    &-description,
    &-detail {
      font-size: 24px;
    }
    &-image {
      margin-bottom: 40px;
      img {
        height: 100px;
      }
    }
  }
}
